

























































































































































































import { Vue, Component, Ref, Watch } from 'vue-property-decorator'
import SeletorDeMarca from '@/components/produto/SeletorDeMarca.vue'
import SeletorDeLojasDoUsuario from '@/components/loja/SeletorDeLojasDoUsuario.vue'
import { Estoque, Page, SituacaoDoProdutoNaLoja } from '@/models'
import { CancelToken, CancelTokenSource } from 'axios'
import AlertModule from '@/store/vuex/aplicacao/AlertModule'
import DataTableDeCrudPaginado from '@/components/ui/DataTableDeCrudPaginado.vue'
import { Pageable } from '@/models/Pageable'
import { FindEstoqueUseCase, FindProdutoUseCase } from '@/usecases'
import { formatarMoeda } from '@/shareds/formatadores'
import copy from 'copy-to-clipboard'

@Component({
	components: {
		SeletorDeMarca,
		DataTableDeCrudPaginado,
		SeletorDeLojasDoUsuario,
	},
})
export default class PainelDaSituacaoDosProdutos extends Vue {
	@Ref() dataTablePaginado!: DataTableDeCrudPaginado
	@Ref() readonly campoDeBusca!: HTMLInputElement

	produtos: SituacaoDoProdutoNaLoja[] = []
	estoques: Estoque[] = []
	pagina: Page<SituacaoDoProdutoNaLoja> | null = null
	cancelToken: CancelTokenSource | null = null

	findEstoqueUseCase = new FindEstoqueUseCase()
	findProdutoUseCase = new FindProdutoUseCase()
	formatarMoeda = formatarMoeda

	carregando = false
	marcasId: string[] = []
	lojaId: string | null = ''
	divergenciasFiscais = true
	divergenciasNoEstoque = true
	divergenciasNoPreco = true
	busca: string | null = ''

	copyToClipboard = copy
	AlertModule = AlertModule

	headers = [
		{ text: 'Produto', value: 'nomeDoProduto', sortable: false },
		{ text: 'SKUs', value: 'sku', sortable: false },
		{ text: 'Marca', value: 'nomeDaMarca', sortable: false },
		{ text: 'Fiscal', value: 'feedbackFiscal', sortable: false, align: 'center' },
		{ text: 'Estoque', value: 'feedbackDoEstoque', sortable: false, align: 'center' },
		{ text: 'Preço', value: 'feedbackDosPrecos', sortable: false, align: 'center' },
	]

	async buscarProdutos(paginavel: Pageable, parametros: {
		marcas?: string
		divergenciasFiscais?: boolean
		divergenciasNoEstoque?: boolean
		divergenciasNoPreco?: boolean
		buscaPorSkuOuEan?: string
	}, cancelToken: CancelToken) {
		if (!this.lojaId) return []
		const pagina = await this.findProdutoUseCase.buscaSituacaoDosProdutos(this.lojaId, {
			...parametros,
			...paginavel,
		}, {
			cancelToken,
		})
		this.produtos = pagina.content
		
		return pagina
	}

	get produtosFormatados() {
		return this.produtos.map(informacoesDoProduto => ({
			...informacoesDoProduto,
			nomeDaMarca: informacoesDoProduto.marca?.nome || '',
		}))
	}

	aplicarFiltros() {
		if (!this.lojaId) AlertModule.setError('Selecione uma loja para prosseguir')
		this.dataTablePaginado.reiniciar({
			marcas: this.marcasId,
			divergenciasFiscais: this.divergenciasFiscais,
			divergenciasNoEstoque: this.divergenciasNoEstoque,
			divergenciasNoPreco: this.divergenciasNoPreco,
			buscaPorSkuOuEan: this.busca,
		})
	}

	@Watch('busca')
	onChangeBusca() {
		if (!this.busca) return
		this.dataTablePaginado.reiniciar({
			marcas: this.marcasId,
			divergenciasFiscais: this.divergenciasFiscais,
			divergenciasNoEstoque: this.divergenciasNoEstoque,
			divergenciasNoPreco: this.divergenciasNoPreco,
			buscaPorSkuOuEan: this.busca,
		})
	}
}
